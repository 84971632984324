<script setup lang="ts">
import {getSitePublic} from "~/utils/base/common-utils";

const {panel_url} = getSitePublic()
</script>

<template>
  <div class="relative flex items-center justify-center bg-primary-500 text-white rounded-lg shadow-md p-8 overflow-hidden ring-2 ring-gray-300 shadow-xl">
    <div class="text-center z-10">
      <h2 class="text-3xl font-bold mb-4 tracking-tight">فروشت رو چند برابر کن</h2>
      <p class="text-lg mb-6 tracking-tight font-semibold">
        همین الان وبسایت گردشگری خودت رو ثبت کن و فروشت رو چند برابر کن.
      </p>
      <div class="flex justify-center gap-4">
        <u-button
            label="ثبت نام"
            :to="panel_url"
            :external="true"
            class="px-6 py-3 bg-black text-white font-semibold rounded-full hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white"
        />
      </div>
    </div>

      <div class="absolute w-20 h-20 bg-primary-400 shadow-inner shadow-2xl shadow-white rounded-full opacity-30 -right-10 z-40"></div>
      <div class="absolute w-40 h-40 bg-primary-400 shadow-inner shadow-2xl shadow-white rounded-full opacity-25 -right-20 z-30"></div>
      <div class="absolute w-56 h-56 bg-primary-400 shadow-inner shadow-2xl shadow-white rounded-full opacity-20 -right-24 z-20"></div>
      <div class="absolute w-64 h-64 bg-primary-400 shadow-inner shadow-2xl shadow-white rounded-full opacity-15 -right-24 z-10"></div>
      <div class="absolute w-72 h-72 bg-primary-400 shadow-inner shadow-2xl shadow-white rounded-full opacity-10 -right-24 z-5"></div>
      <div class="absolute w-80 h-80 bg-primary-400 shadow-inner shadow-2xl shadow-white rounded-full opacity-5 -right-20 z-1"></div>

    <div class="absolute w-20 h-20 bg-primary-400 shadow-inner shadow-2xl shadow-white rounded-full opacity-30 -left-10 z-40"></div>
    <div class="absolute w-40 h-40 bg-primary-400 shadow-inner shadow-2xl shadow-white rounded-full opacity-25 -left-20 z-30"></div>
    <div class="absolute w-56 h-56 bg-primary-400 shadow-inner shadow-2xl shadow-white rounded-full opacity-20 -left-24 z-20"></div>
    <div class="absolute w-64 h-64 bg-primary-400 shadow-inner shadow-2xl shadow-white rounded-full opacity-15 -left-24 z-10"></div>
    <div class="absolute w-72 h-72 bg-primary-400 shadow-inner shadow-2xl shadow-white rounded-full opacity-10 -left-24 z-5"></div>
    <div class="absolute w-80 h-80 bg-primary-400 shadow-inner shadow-2xl shadow-white rounded-full opacity-5 -left-20 z-1"></div>
  </div>
</template>

